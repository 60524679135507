<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                    label="İsim"
                    horizontal
                    v-model="data.name"
                />
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="Başvuru Tarihi"
                    horizontal
                    type="date"
                    v-model="data.startTime"
                />
              </CCol>
              <CCol>
                <CInput
                    label="Başvuru Tarihi"
                    horizontal
                    type="date"
                    v-model="data.endTime"
                />
              </CCol>
              
            </CRow>
            
            <CRow>
              <CCol>
                <CInput
                    label="E-mail"
                    horizontal
                    v-model="data.emailAddress"
                />
              </CCol>
              <CCol>
                <CInput
                    label="Telefon"
                    horizontal
                    v-model="data.phoneNumber"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CSelect
                  label="Departman"
                  placeholder="Departman"
                  horizontal
                  :options="careerPositions"
                  :value.sync="data.careerPosition"
                >
                </CSelect>
              </CCol>
              <CCol>
                <CSelect
                  label="İş İlanı"
                  placeholder="İş İlanı"
                  horizontal
                  :options="careers"
                  :value.sync="data.career"
                >
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CRow>
                  <CCol>
                    <CSelect
                      label="Durum"
                      horizontal
                      :options="statusList"
                      :value.sync="data.status"
                    />
                  </CCol>
                  <CCol>
                    <CInput
                        label="Ön Yazı"
                        horizontal
                        v-model="data.coverLetter"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                    label="Not"
                    horizontal
                    v-model="data.note"
                />
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "CareerApplicantFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       computed:{
          careers: function() {
              let data = [{value: undefined, label: 'İş İlanı Seçin'}]
              this.$store.getters.jobPostings.map(r => data.push({value:r.id, label: r.title}))
              return data
          },
          careerPositions: function() {
              let data = [{value: undefined, label: 'Departman Seçin'}]
              this.$store.getters.careerPositions.map(r => data.push({value:r.id, label: r.name}))
              return data
          },
          statusList: function() {
              let data = ['Seçiniz']
              this.$store.getters.careerStatusTypes.map(cst => data.push({label: cst.friendlyType, value: cst.type}))
              return data ;
          },
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>